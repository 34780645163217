button.nav-link {
    color: black;
}

.accordion {
   .accordion-header button{
    font-size: 1.1rem;
   }
    .accordion-body {

        img {
            
            @media (max-width:1400px) {
                padding-top: 20px;
               
            }
            @media (max-width:992) {
               width: 400px;
               
            }
        }


    }
}