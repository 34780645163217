.mains{
    display: block;
    position: relative;
   &::before{
    content: "";
    background-image: url("../../assets/img/main_bg.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    opacity:0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;  
   
   
   }
}

.main-title{
    text-align: center;
    margin:50px 0 15px 0;
    color: rgb(121, 119, 119);
    margin-bottom: 30px;
    margin-top: 80px;
}
.news{
    @media (max-width:992px) {
        width:150;
        height:150;
    }
position: relative;
margin-left:20px;
.news-content{
    position: absolute;
    top: 80%;
    left: 0;
    transform: translateX(-50%,-50%);
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.513);
    height: 40px;
    width: 200px;
    color: white;
    font-size: 16px;
   padding-top: 5px;
}
}
