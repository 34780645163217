

.slider {
    .swiper-wrapper {
        .swiper-slide {
            position: relative;
            // &::after {
            //     content: "";
            //     position: absolute;
            //     top: 0;
            //     bottom: 0;
            //     left: 0;
            //     right: 0;
            //     background-color: rgba(173, 216, 230, 0.64);
            // }

            img {
                object-fit: cover;
                width: 100%;
                height: 100vh;
            }

            .content {
                position: absolute;
                top: 30%;
                left: 50%;
                transform: translate(-50%);
                text-align: center;
                color: white;
                z-index: 5;

                h2{
                    font-size: 3rem;
                    font-weight: 600;
                    text-shadow: 0 0 20px rgb(8, 8, 8);
                }
            }
        }
    }

    .swiper-button-prev,.swiper-button-next{
        // color: v.$color2;
    }
    .swiper-pagination-bullet-active{
    //    background-color:v.$color2;
    }
}