@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

.App {

    font-family: Georgia, 'Times New Roman', Times, serif;



}

html {
    width: 100%;
    height: 100%;
}