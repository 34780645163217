

.page-header {
    background-image:url("../../assets/img/slider1.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 130px;
    padding-bottom: 1rem;
    position: relative;
    // opacity: 0.7;
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color:rgba(0, 0, 0, 0.981);
        opacity: 0.5;
    }

    h1 {
        color:rgb(235, 224, 224);
        z-index: 1;
        font-size: 3rem;
        font-weight: 700;
         text-shadow: 0 0 30px rgb(94, 94, 100);


        @media (max-width:600px) {
            content: "";
            font-size: 3rem;
          
        }

        @media (max-width:500px) {
            content: "";
            font-size: 2.5rem;
          
        }

        @media (max-width:400px) {
            content: "";
            font-size: 2rem;
           
        }
        @media (max-width:779px) {
            content: "";
           padding-left: 20px;
           
        }

    }
}