.navbar{
  position: relative;

@media (max-width:992px) {
  .navbar-nav{
    display: none;
    padding: 30px;
  }
}
.navbar-nav{
  font-size: 16px;
  font-weight: 400;
}

img{
position: absolute;
width: 130px;
top:-10px;
@media (max-width:992px) {
  top: 0;
}
@media (max-width:1200px) {
  left: 0;
}
@media (max-width:1017px) {
  left: 0;
}
@media (max-width:1100px) {
  left: 0;
}
}

#btnOffanvas{
  font-size: 35px;
  color: black;
}
}
@media (min-width:992px) {
  #btnOffanvas{
    display: none;
  }
}

.scrolled {
  padding-top: 7px;
  padding-bottom: 7px;
  background-color:white


  ;
}

