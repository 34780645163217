.footer-container {
    // background-color: rgba(242, 160, 9, 0.917);
   background-color: #26AACA;
    margin-top: 50px;
    color:rgb(0, 0, 0);
 
 

    
  


    .footer-list {
        list-style: none;
    }

    a {
        text-decoration: none;
        color:rgb(0, 0, 0);
        font-weight: 400;
    }

    .list {
        margin: 0;
        padding: 50px;
        list-style-type: none;
        font-weight: 400;

    }

    .sossial-list {

        li:first-child {
            margin: 0;
        }

        li {
            margin-bottom: 10px
        }
    }

}
