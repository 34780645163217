.about {
    background-image: url("../../assets/img/bizkimiz.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 450px;
    @media (max-width:514px) {
      height: 700px;
    }
  ul{
    list-style-type: none;
    padding-left: 70px;
    padding-top: 40px;
    li,h3{
        color:white;

    }
    h4{
        font-weight: 600;
    }
  }
  }
  
 