.team {

    .team-info {
        display: flex;
        flex-direction: column;
        gap: .1rem;
        font-weight: 500;
    }

    img {
        border-radius: 0;
        padding: 1rem;

    }

    .card {
        border: 0;
        margin-bottom: 1.4rem;

        .person-info-list{
            list-style-type: none;
            line-height: 1.5rem;
            font-weight: 600;
            padding: 0;
        }
    }

}